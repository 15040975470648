
"use client"
// 
import localFont from "next/font/local";
import "./globals.css";

import Image from "next/image";
import logo from "./images/evans-energy-logo.png"
// import Link from "next/link";
import Head from 'next/head'

import isBrowser from "./helpers/is_browser";
import { useEffect, useState } from "react";
import {pageDataAtom, hasNetworkAtom} from "@/app/state"
import { useAtom } from "jotai";
import OfflineBanner from "./components/offline_banner";
// import InstallPrompt from "./components/install_prompt";
// import PushNotificationManager from "./components/push_notifications_manager";

// import type { Metadata } from "next";
// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };

const geistSans = localFont({
  src: "./fonts/GeistVF.woff",
  variable: "--font-geist-sans",
  weight: "100 900",
});
const geistMono = localFont({
  src: "./fonts/GeistMonoVF.woff",
  variable: "--font-geist-mono",
  weight: "100 900",
});


function LeftProjectLogo({ project }) {

  if (!project) {
    return null
  }

  return (<>
    <div className="logo-forward" style={{ alignSelf: 'center', marginLeft: '-0.8rem' }}>
      <span className="material-symbols-outlined">arrow_forward</span>
    </div>
    <div className="flex items-center">
      <Image alt="project images" src={project.icon} />
    </div>
  </>)
}

function DisplayName({ name }) {
  if (!name) {
    return null
  }
  return (
    <>{name}</>
  )

}

function AdminLogout({loggedIn}) {
  if(isBrowser() && !loggedIn) {
    console.log("loggedIn 1", loggedIn)
    return null
  }
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
        <a style={{ color: "white" }} href={"/login"}>Home</a>
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/admin/logout"}>Log Out</Link> */}
      </li>
    </>
  )
}



function UserLogout({loggedIn}) {
  if(isBrowser() && !loggedIn) {
    console.log("loggedIn 1", loggedIn)
    // return null
  }
  return (
    <>
      <li style={{ margin: '0', paddingRight: '1rem' }} >
        <a style={{ color: "white" }} href={"/login"}>Home</a>
        {/* <Link style={{ color: "white" }} href={"/login"}>Home</Link> */}
      </li>
      <li style={{ margin: 0 }}>
        <a style={{ color: "white" }} href="/logout">Log Out</a>
        {/* <Link style={{ color: "white" }} href={"/accounts/sign_out"}>Log Out</Link> */}
      </li>
    </>)
  
}

// eslint-disable-next-line
function AppInstallBanner() {
  let host=""
  if(isBrowser()) {
    host = location.hostname 
  }
  if(host !== "evans-energy-b9ba239f1ce7.herokuapp.com") {
    return null
  }

  return (
    <div className="w-full bg-red-600 text-white justify-center flex font-bold p-4">  
      <a href="/assets/evans_energy2.apk">Install the app</a>
    </div>
  )
}


export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {

  // if(isBrowser()) {
  //   console.log("******************************")
  //   console.log(window.pageData)
  // }


  // eslint-disable-next-line
  const [pageData, setPageData] = useAtom(pageDataAtom)
  
  const [loggedIn, setLoggedIn] = useState(false)

  const [wellAdmin, setWellAdmin] = useState(false)
  const [wellUser, setWellUser] = useState(false)
  const [fullAdmin, setFullAdmin] = useState(false)
  const [project, setProject] = useState("")
  const [name, setName] = useState("")
  const [initComplete, setInitComplete] = useState(false)
  const [hasNetwork, setHasNetwork] = useAtom(hasNetworkAtom)

  // const cacheContent = async () => {
  //   if(isBrowser()) {
  
  //     const res = await window.serwist.messageSW({ action: 'page-data-update' });
  //     console.log("PAGE DATA UPDATE")
  //   }
  // };

  // window.addEventListener('page-data-update-response', (ev) => {
    
  // })
    

//   const cacheContent = async () => {
//     if(isBrowser()) {
  
//       const res = await window.serwist.messageSW({ action: 'page-data-update' });
//       console.log("PAGE DATA UPDATE")
//     }
//   };

//   window.addEventListener('page-data-update-response', (ev) => {
// debugger    
//   })

  useEffect(() => {
    let data;

  
    if (!initComplete && isBrowser() && window && window.pageData) {
      data = window.pageData
    } else if (!initComplete && isBrowser() && pageData !== null) {
      window.pageData = pageData
      data = pageData
    }

    if (!initComplete && isBrowser()) {

      setLoggedIn(() => data.loggedIn)
      setWellAdmin(() => data.wellAdmin)
      setWellUser(() => data.wellUser)
      setFullAdmin(() => data.fullAdmin)

      if(data.loggedIn) {
        setName(() => data.name)
        setProject(() => data.project)
      }

      setInitComplete(() => true)
      if(pageData === null) {
        setPageData(window.pageData)
      }
    }
    
    if(isBrowser() && ((fullAdmin || wellAdmin || wellUser))) {
      // console.log('TICK')
      const cachedHasNetworkItem = localStorage.getItem("has_network")

      if(cachedHasNetworkItem !== null) {
        setHasNetwork(cachedHasNetworkItem === '1')
      }
      
      const heartbeatInt = setInterval(() => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 2000);
        fetch(`/api/ping`, {signal: controller.signal}).then(res => {
          // debugger
          if(res.status === 200 && !hasNetwork) {
            localStorage.setItem('has_network', '1')
            console.log("YYYlkjasdlfjasdl;kfjasdl;k")
            setHasNetwork(true)
          } else if(res.status !== 200 && hasNetwork) {
            localStorage.setItem('has_network', '0')
            setHasNetwork(false)
          }
        }).catch(() => {
          if(hasNetwork) {
            localStorage.setItem('has_network', '0')
            setHasNetwork(false)
          }
        }).finally(() => {
          clearTimeout(timeoutId);
        })
      }, 3000)

      return () => clearInterval(heartbeatInt)
    }
  }, [loggedIn, project, name, pageData, wellAdmin, wellUser, fullAdmin, hasNetwork])


  // eslint-disable-next-line
  async function checkSWVersion() {
    const version = await serwist.messageSW({type: 'GET_VERSION'})
    console.log("VERSION ", version)
  }

  function isAdmin() {
    return wellAdmin || wellUser || fullAdmin
  }


  return (
    <html lang="en">
      <Head>
        <title>Evans Energy II - Joint Venture Oil & Gas</title>
        <link rel="manifest" href="/manifest.json" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
      </Head>
      <body
        className={`${geistSans.variable} ${geistMono.variable} antialiased`}
      >
        {/* <PushNotificationManager /> */}
        <OfflineBanner />
        {/* <AppInstallBanner /> */}
        <nav className="header border-gray-200">
          
          <div style={{ height: '100%' }}>
            <div className="flex" style={{ height: '100%' }}>
              <div className="flex" style={{ maxWidth: '355px', height: '100%', padding: '0 1rem 0 1rem' }}>
                <a href="/projects" className="flex items-center space-x-3 rtl:space-x-reverse no-underline">
                  <Image alt="Evans Energy Logo" src={logo} style={{ width: '175px' }} />
                </a>


                <LeftProjectLogo project={project} />

              </div>

              <div className="flex header_account_name">
                <div className="hidden md:block" style={{ margin: '0 auto', alignContent: 'center' }} >
                  <h1 className="project_name" >
                    <DisplayName name={name} />
                  </h1>
                </div>

                {!loggedIn ? null : (
                  <div className="w-full md:block md:w-auto" id="navbar-default" style={{ alignContent: 'center', paddingRight: '1.5rem' }}>
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border-none md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 list-none text-xl">
                      {isAdmin() ? <AdminLogout loggedIn={loggedIn} /> : <UserLogout loggedIn={loggedIn} />}
                    </ul>
                  </div>
                )}


              </div>
            </div>
          </div>
        </nav >
        {/* <InstallPrompt /> */}
        {children}
      </body >
    </html >
  );
}
